import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NonAtsCandidate, CandidateActivitiesAssignedJobsReq } from 'src/app/models/candidatemodel/non-ats-candidate';
import { CandidateNotesRequest } from 'src/app/models/candidateNotes/candidate-notes';

@Injectable({
  providedIn: 'root'
})
export class NonAtsCandidateService {
  private apiURL: string = environment.apiURL;
  private apiController: string = "v1/nonatscandidate";

  constructor(private httpClient: HttpClient) { }


  public insertNonAtsCandidate(formData: any) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/insertNonAtsCandidate/`, formData);
  }
  public getNonAtsCandidateDetails(candidateId: number) {
    return this.httpClient.get(`${this.apiURL}/${this.apiController}/non-ats-candidate-details/${candidateId}`);
  }

  public updateNonAtsCandidate(candidate: NonAtsCandidate) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/updateNonAtsCandidate/`, candidate);
  }

  public getNonAtsNetworkCandidateDetails(networkCandidateId: number) {
    return this.httpClient.get(`${this.apiURL}/${this.apiController}/non-ats-networkCandidate-details/${networkCandidateId}`);
  }

  public getNonAtsNetworkSubmittedCandidateDetails(networkSubmittedCandidateId: number) {
    return this.httpClient.get(`${this.apiURL}/${this.apiController}/getNonAtsNetworkSubmittedCandidateDetails/${networkSubmittedCandidateId}`);
  }

  public uploadBulkCandidatesNonAts(formData: any) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/uploadBulkCandidatesNonAts/`, formData);
  }

  public candidateGrid(candidateFilter) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/candidate-grid/`, candidateFilter);
  }

  public getCandidateGridMasterData(isArchived: boolean) {
    return this.httpClient.get(`${this.apiURL}/getCandidateGridMasterData/${isArchived}`);
  }
  public getCandidateAssignedJobs(candidateAssignedJobsReq: CandidateActivitiesAssignedJobsReq) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/candidate-assignedJobs/`, candidateAssignedJobsReq);
  }
  public getCandidateActivities(candidateActivityReq: CandidateActivitiesAssignedJobsReq) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/candidate-activities/`, candidateActivityReq);
  }
  public candidateNotesHistory(candiadateNotesHistoryReq: CandidateNotesRequest) {
    return this.httpClient.post(`${this.apiURL}/${this.apiController}/candidate-notes-history/`, candiadateNotesHistoryReq);
  }
} 
