
export class Availability {
  joiningAvailabilityId: number;
  joiningAvailability: string;
}

export class WorkAuthorizationModel {
  workAuthorizationTypeId: number = -1;
  workAuthorizationType: string;
}

export class EmploymentTypeModel {
  employmentTypeId: number;
  employmentType: string;
}

export class FeedbackCategory {
  categoryId: number;
  category: string;
}


