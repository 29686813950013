
export class Contactperson {

  contactPersonId: number;
  contactPerson: string;
  contactNumber: string;
  emailId: string;
  clientId: number;
  isActive: boolean;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
  note: string = "";
  isEmailValid: boolean = true;
  isValidContactNumber: boolean;
  isValidContactPerson: boolean;
  isEmailIdDuplicate: boolean;
  hasDependent: boolean; //PR 3318
  isDeleted: boolean; //PR 3318
}
