export class ConnectionProfileForShareUnshare {
  //Profile Fields
  recipientCompanyId: number;
  recipientUserId: number;
  name: string;
  location: string;
  workTitleAndCompany: string;
  profileImageBlobName: string;

  //Connection Fields
  connectionId: number;
  connectionTypeId: number;

  isShared: boolean;
  isReadOnly: boolean;
  shareTypeId: number;
  networkJobId: number;
  networkCandidateId: number;
}
