export class CandidateGridFilterByFields {
  candidateId: number;
  candidateName: string = '';
  candidateJobTitle: string = '';
  workAuthorizationTypeId: number;
  location: string = '';
  minRate: number;
  maxRate: number;
  actionBy: number;
  actionDate: string = '';
  orderBy: string = '';
  orderByReverse: boolean = true;
  pageSize: number = 10;
  pageNumber: number = 0;
  experienceInYears: string = '';
  minExperience: number;
  maxExperience: number;
  rate: string = '';
  globalSearchTerm: string = '';
  isInContract: boolean = true;
  onboardedJobTitle: string;
  clientName: string;
  contractStartDate: string;
  contractEndDate: string;
  sharedByOrCreatedBy: string;
  candidatesFilterTypeId: number;
  candidateTabId: number;
}
