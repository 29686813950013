import { Attachments } from '../attachments/attachments';

export class Activity {
  activityMessage: string;
  userImage = new Attachments();
  timeSinceActivity: string;
  performedDate: Date;
  userImageBlobName: string; //PR 3367 For image display
  performedBy: string;
}
