export class CandidateNotesRequest {
  isNetworkCandidate: boolean;
  candidateId: number;
  networkCandidateId: number;
  notes: string;
}
export class CandidateNotesResult {
  notes: string;
  performedBy: string;
  performedDate: Date;
  userImageBlobName: string;
}
