import { Contactperson } from '../contactpersonmodel/contactperson';

export class Client {

  clientId: number;
  client: string;
  companyId: number ;
  companyUserId: number ;
  isActive: Boolean;
  createdBy: number ;
  createdDate: string;
  modifiedBy: number=0;
  modifiedDate: string;
  isExpanded: boolean;
  contactPersons: Contactperson[];
}
