import { Attachments } from '../attachments/attachments';
import { Availability } from '../master/master';
import { AssignedJobs } from 'src/app/models/assignedjobs/assignedjobs';
import { Activity } from '../activity/activity';
import { CompanyUser } from '../companyusermodel/companyuser';
import { Location } from '../location'

export class NonAtsCandidate {
  isNetworkCandidate: boolean;
  candidateId: number;
  networkCandidateId: number;
  firstName: string;
  lastName: string;
  candidateName: string;
  candidateJobTitle: string;
  emailId: string;
  contactNumber: string;
  candidateLocation = new Location();
  cityId: number;
  locationName: string;
  isWillingToRelocate: boolean;
  joiningAvailability = new Availability();
  networkIsHourlyRate: boolean = true;
  networkRate: number;
  candidateAttachments: Attachments[];
  isFavourite: boolean;
  isBlacklisted: boolean;
  isOnBoarded: boolean;
  isNetworkCloned: boolean;
  isActive: boolean;
  CreatedBy: number;
  CreatedByName: string;
  createdDate: string;
  experienceInYears: number;
  experienceInMonths: number;
  assignedJobs: AssignedJobs[];
  activities: Activity[];
  notes: string = "";
  comment: string = "";
  isCandidateNameConfirmed: boolean;
  candidateImage: Attachments = new Attachments();
  joiningAvailabilityJSON: string;
  networkStatusId: number;
  timeZone: string = "";
  resumeName: string;
  candidateAttachmentsJSON: string;
  candidatePotentialId: number;
  skills: string;
  isContactShareable = false;
  isEmailShareable = false;
}

export class CandidateActivitiesAssignedJobsReq{
  isNetworkCandidate: boolean;
  candidateId: number;
  networkCandidateId: number;
}
